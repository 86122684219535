<mat-sidenav-container [ngClass]="sidebarIsOpen ? 'sidenav-short-mode' : ''">
  <mat-sidenav #sidenav mode="side" opened fixedInViewport="true" fixedTopGap="64">
    <mat-nav-list>
      <div class="sidenav-btn-container">
        <button (click)="handleSideBarMenu()" class="sidenav-btn-handler">
          <img width="24px" height="24px" src="./assets/icons/side-nav/administrator/left-arrow.svg" alt="" />
        </button>
        <button (click)="handleSideBarMenu()" class="sidenav-btn-close-handler">
          <img width="24px" height="24px" src="./assets/icons/side-nav/administrator/right-arrow.svg" alt="" />
        </button>
      </div>
      <a mat-list-item [ngClass]="sidebarIsOpen ? 'sidenav-short-mode' : ''" class="sidenav-item"
        routerLink="/administrador/dashboard" routerLinkActive="active-link">
        <div class="dh-icon-dashboard">
          <img width="21px" height="21px" src="./assets/icons/side-nav/administrator/dashboard.svg"
            alt="dashboard de administrador" />
        </div>
        <span class="nav-text">
          {{ 'ADMINISTRATOR.navOptions.dashboard' | translate }}
        </span>
        <span class="selection-bar"></span>
      </a>
      <a mat-list-item class="sidenav-item" routerLink="/administrador/seccion/solicitudes/"
        routerLinkActive="active-link">
        <div class="dh-icon-writting dh-icon-mobile">
          <img width="21px" height="21px" src="./assets/icons/side-nav/administrator/writting.svg" alt="solicitudes" />
        </div>
        <span class="nav-text">
          {{ 'ADMINISTRATOR.navOptions.requests' | translate }}
        </span>
        <span class="selection-bar"></span>
      </a>
      <a mat-list-item class="sidenav-item" routerLink="/administrador/proveedores"
        routerLinkActive="active-link">
        <div class="dh-icon-store dh-icon-mobile">
          <img width="21px" height="21px" src="./assets/icons/side-nav/administrator/store.svg"
            alt="detalles de pedidos de proveedor" />
        </div>
        <span class="nav-text">
          {{ 'ADMINISTRATOR.navOptions.providers' | translate }}
        </span>
        <span class="selection-bar"></span>
      </a>
      <a mat-list-item class="sidenav-item" [routerLink]="['/administrador/seccion/prospectos']" title=""
        [ngClass]="{ 'active-link': isProspectsActive }">
        <div class="dh-icon-person-in-document dh-icon-mobile">
          <img width="21px" height="21px" src="./assets/icons/side-nav/administrator/person-in-document.svg"
            alt="Prospectos" />
        </div>
        <span class="nav-text">
          {{ 'ADMINISTRATOR.navOptions.prospects' | translate }}
        </span>
        <span class="selection-bar"></span>
      </a>
      <a mat-list-item class="sidenav-item" routerLink="/administrador/clientes" routerLinkActive="active-link">
        <div class="dh-icon-people dh-icon-mobile">
          <img width="21px" height="21px" src="./assets/icons/side-nav/administrator/people.svg" alt="Clientes" />
        </div>
        <span class="nav-text">
          {{ 'ADMINISTRATOR.navOptions.clients' | translate }}
        </span>
        <span class="selection-bar"></span>
      </a>
      <span class="dh-disable-btn">
        <!-- <a mat-list-item class="sidenav-item dh-disable-btn">
          To add in future versions
          routerLink="/administrador/seccion/reportes" routerLinkActive="active-link"
          <div class="dh-icon-statistics dh-icon-mobile dh-disable-btn">
            <img width="21px" height="21px" src="./assets/icons/side-nav/administrator/statistics.svg" alt="reportes" />
          </div>
          <span class="nav-text">
            {{ 'ADMINISTRATOR.navOptions.reports' | translate }}
          </span>
          <span class="selection-bar"></span>
        </a> -->
      </span>
      <a mat-list-item class="sidenav-item" routerLink="/administrador/usuarios" routerLinkActive="active-link">
        <div class="dh-icon-people-group dh-icon-mobile">
          <img width="21px" height="21px" src="./assets/icons/side-nav/administrator/people-group.svg" alt="usuarios" />
        </div>
        <span class="nav-text">
          {{ 'ADMINISTRATOR.navOptions.users' | translate }}
        </span>
        <span class="selection-bar"></span>
      </a>
    </mat-nav-list>
  </mat-sidenav>
</mat-sidenav-container>