<div class="dh-header-app">
  <div class="dh-header-box dh-flex-row-align-center-justific-space-between">
    <div class="dh-header-box-l dh-flex-row-align-center-justific-space-between">
      <mat-icon (click)="goRouter('proveedor/reservas')"
        class="dh-header-box-l-logo dh-decoration-curosr-pinter dh-logo-mobile" svgIcon="ic-logo"></mat-icon>
    </div>

    <div class="dh-header-box-r dh-flex-row-align-center-justific-space-between">
      <div *ngIf="!mobile">
        <div class="dh-header-box-rol">
          <div class="dh-header-box-docoration-line"></div>
          <p class="dh-header-box-name">{{roles}}</p>
        </div>
      </div>

      <div *ngIf="!loading && showProducts && completeConfig"
        class="dh-menu dh-header-box-r dh-flex-row-align-center-justific-space-between">
        <div class="dh-profile dh-flex-row-align-center-justific-space-evenly">
          <div class="dh-button-provider">
            <button mat-stroked-button color="primary" (click)="showCreateProductModal()">
              <div class="dh-button-upload">
                <img src="../../../../assets/icons/upload_products.svg" alt="Subir producto" />
                <p>{{ 'PROVIDER.uploadProducts' | translate }}</p>
              </div>
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="!mobile"
        class="dh-cp-box dh-flex-row-align-center-justific-space-between dh-decoration-curosr-pinter">
        <div (click)="goMenuMovile()">
          <mat-icon *ngIf="mobile" class="dh-menu-icon" svgIcon="ic-menu"></mat-icon>
        </div>
      </div>

      <div *ngIf="!mobile && !loading"
        class="dh-profile dh-flex-row-align-center-justific-space-evenly dh-login-section">
        <img src="../../../../assets/icons/user-icon.svg" alt="icono de usuario" width="25px" height="25px" />
        <div [ngClass]="{ 'dh-text-profil': login, 'dh-text-profil-off': !login }"
          class="dh-text-profil dh-flex-column-justify-start">
          <p *ngIf="login" class="dh-text-regular-poppins dh-color-grey-649">
            {{ 'HOME.account' | translate }}
          </p>
          <p [ngClass]="{ 'dh-decoration-curosr-pinter': !login }" class="dh-text-regular-big-poppins dh-color-blue">
            {{ login ? data.name + ' ' + data.surname : '' }}
          </p>
        </div>
        <mat-icon *ngIf="login" class="dh-icon-blue dh-decoration-curosr-pinter dh-arrow-display-info"
          svgIcon="ic-arrow-bottom" [matMenuTriggerFor]="menu"></mat-icon>
      </div>
    </div>
  </div>
  <mat-menu class="dh-contain-header-menu dh-profile-menu-header" #menu="matMenu">
    <div class="dh-icon-section">
      <mat-icon class="dh-icon-blue dh-decoration-curosr-pinter dh-icon-in-menu" svgIcon="ic-arrow-bottom"></mat-icon>
    </div>

    <button *ngIf="showMoreOptions" type="button" mat-button class="dh-menu-element">
      <a class="dh-menu-element-link dh-menu-element-link-effect" routerLink="/proveedor/perfil" title=""
        routerLinkActive="active-link">
        <div class="dh-icon-profile dh-icon-desktop">
          <img width="20px" height="20px" src="./assets/icons/side-nav/provider/profile-active.svg"
            alt="perfil de proveedor" />
        </div>
        <p>{{ 'HOME.profile' | translate }}</p>
      </a>
    </button>

    <button *ngIf="showMoreOptions" type="button" mat-button class="dh-menu-element">
      <a class="dh-menu-element-link dh-menu-element-link-effect" routerLink="/proveedor/bitacora" title=""
        routerLinkActive="active-link">
        <div class="dh-icon-tasks dh-icon-desktop">
          <img width="20px" height="20px" src="./assets/icons/side-nav/provider/close-book-active.svg"
            alt="bitacora de proveedor" />
        </div>
        <p>{{ 'Ver Bítacora' }}</p>
      </a>
    </button>

    <hr class="dh-line-profile-menu" />
    <button (click)="goToSignOff()" mat-menu-item
      class="dh-button-header-mat-menu-item dh-logout dh-menu-element-link-effect">
      <img src="../../../../assets/icons/logout-icon-blue.svg" class="dh-menu-icon dh-logout-icon" width="21px"
        height="21px" alt="Cotizar concretro premezclado" />
      <span class="dh-text-menu">{{ 'HOME.signOff' | translate }}</span>
    </button>
  </mat-menu>
</div>

<div *ngIf="mobile" class="dh-menu-mobile">
  <div class="dh-menu-mobile-row" [ngClass]="{'dh-handle-operator-menu': isOperatorUr}">

    <button type="button" mat-button class="dh-menu-mobile-element">
      <a (click)="logout()">
        <div class="dh-icon-profile dh-icon-mobile">
          <svg width="24" height="24" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Subir_archivos">
              <path id="Vector" d="M13.333 11.3333L6.54134 11.3333L8.70801 13.5L7.49967 14.6666L3.33301 10.5L7.49967 6.33329L8.70801 7.49996L6.54134 9.66662L13.333 9.66662L13.333 11.3333ZM16.6663 15.5C16.6663 15.9583 16.503 16.3508 16.1763 16.6775C15.8497 17.0041 15.4575 17.1672 14.9997 17.1666L12.4997 17.1666L12.4997 15.5L14.9997 15.5L14.9997 5.49996L12.4997 5.49996L12.4997 3.83329L14.9997 3.83329C15.458 3.83329 15.8505 3.99662 16.1772 4.32329C16.5038 4.64996 16.6669 5.04218 16.6663 5.49996L16.6663 15.5Z" fill="#c4c4c4"/>
            </g>
          </svg>
          
        </div>
      </a>
    </button>


    <button type="button" mat-button class="dh-menu-mobile-element" *ngIf="!isOperatorUr && authService.canUserSeeProfile()">
      <a routerLink="/proveedor/perfil" title="" routerLinkActive="active-link">
        <div class="dh-icon-profile dh-icon-mobile">
          <img width="21px" height="21px" class="home" src="./assets/icons/side-nav/provider/profile.svg"
            alt="perfil de proveedor" />
        </div>
      </a>
    </button>

    <button *ngIf="userModuleOptions.includes('products')" type="button" mat-button class="dh-menu-mobile-element">
      <a routerLink="/proveedor/productos" title="" routerLinkActive="active-link">
        <div class="dh-icon-product dh-icon-mobile">
          <img width="21px" height="21px" src="./assets/icons/side-nav/provider/box.svg" alt="productos de proveedor" />
        </div>
      </a>
    </button>

    <button *ngIf="userModuleOptions.includes('orders') && !isOperatorUr" type="button" mat-button
      class="dh-menu-mobile-element">
      <a routerLink="/proveedor/orders" title="" routerLinkActive="active-link">
        <div class="dh-icon-tasks dh-icon-mobile">
          <img width="21px" height="21px" src="./assets/icons/side-nav/provider/tasks.svg"
            alt="detalles de pedidos de proveedor" />
        </div>
      </a>
    </button>

    <button *ngIf="userModuleOptions.includes('binnacle')" type="button" mat-button class="dh-menu-mobile-element">
      <a routerLink="/proveedor/bitacora" title="" routerLinkActive="active-link">
        <div class="dh-icon-menu dh-icon-mobile">
          <img width="21px" height="21px" src="./assets/icons/side-nav/provider/close-book.svg"
            alt="munú de proveedor" />
        </div>
      </a>
    </button>

    <button *ngIf="isOperatorUr && currentDeliveryId" type="button" mat-button
      class="dh-menu-mobile-element">
      <a [routerLink]="'/proveedor/entregas/' + currentDeliveryId " title=""
        routerLinkActive="active-link">
        <div class="dh-icon-truck dh-icon-mobile">
          <img width="21px" height="21px" src="./assets/icons/side-nav/provider/truck.svg" alt="munú de proveedor" />
        </div>
      </a>
    </button>

    <button *ngIf="userModuleOptions.includes('booking') && !isOperatorUr" type="button" mat-button
    class="dh-menu-mobile-element">
    <a [routerLink]="'/proveedor/camiones'" title=""
      routerLinkActive="active-link">
      <div class="dh-icon-truck-2 dh-icon-mobile">
        <img width="21px" height="21px" src="./assets/icons/trucks-icon.svg" alt="munú de proveedor" />
      </div>
    </a>
  </button>

    <button *ngIf="userModuleOptions.includes('users')" type="button" mat-button class="dh-menu-mobile-element">
      <a routerLink="/proveedor/usuarios" title="" routerLinkActive="active-link">
        <div class="dh-icon-people-group dh-icon-mobile">
          <img width="21px" height="21px" src="./assets/icons/side-nav/provider/people-group.svg" alt="usuarios" />
        </div>
      </a>
    </button>

    <button *ngIf="userModuleOptions.includes('booking')" type="button" mat-button class="dh-menu-mobile-element">
      <a routerLink="/proveedor/reservas" title="" routerLinkActive="active-link">
        <div class="dh-icon-calendar dh-icon-mobile">
          <img width="21px" height="21px" src="./assets/icons/side-nav/provider/calendar.svg" alt="reservar entrega" />
        </div>
      </a>
    </button>
  </div>
</div>

<app-flat-loader *ngIf="loader"></app-flat-loader>