<ng-container *ngIf="!confirmStep; else confirm">
  <div mat-dialog-title class="dh-header">
    <h1>
      {{ 'PROVIDER.productData' | translate }}
    </h1>
    <button mat-icon-button mat-dialog-close class="close-button dh-padding-close">
      <mat-icon aria-label="Cerrar">close</mat-icon>
    </button>
  </div>
  <form [formGroup]="formControls">
    <mat-dialog-content class="dh-container">
      <div class="dh-forms dh-addleft-container-left">
        <p class="dh-label-forms">{{ 'PROVIDER.endurance' | translate }}</p>
        <mat-form-field class="dh-form" appearance="outline">
          <mat-select [formControlName]="'endurance'">
            <mat-option *ngFor="let item of resistances" value="{{ item.code }}">
              {{ item.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <p class="dh-label-forms">{{ 'PROVIDER.setting' | translate }}</p>
        <mat-form-field class="dh-form" appearance="outline">
          <mat-select [formControlName]="'setting'">
            <mat-option *ngFor="let item of settings" value="{{ item.code }}">
              {{ item.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <p class="dh-label-forms">{{ 'PROVIDER.aggregate' | translate }}</p>
        <mat-form-field class="dh-form" appearance="outline">
          <mat-select [formControlName]="'aggregate'">
            <mat-option *ngFor="let item of aggregates" value="{{ item.code }}">
              {{ item.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <p class="dh-label-forms">{{ 'PROVIDER.applications' | translate }}</p>
        <mat-form-field class="dh-form" appearance="outline">
          <input [formControlName]="'applications'" matInput />
        </mat-form-field>
        <p class="dh-label-forms">{{ 'PROVIDER.detail_concrate' | translate }}</p>
        <mat-form-field class="dh-form" appearance="outline">
          <textarea [formControlName]="'detail_concrate'" class="" matInput placeholder="--"></textarea>
        </mat-form-field>
      </div>
      <div class="dh-forms dh-addright-container-right">
        <p class="dh-label-forms">{{ 'PROFILE.rev' | translate }}</p>
        <mat-form-field class="dh-form" appearance="outline">
          <mat-select [formControlName]="'slump'">
            <mat-option *ngFor="let item of slumps" value="{{ item.name }}">
              {{ item.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <p class="dh-label-forms">{{ 'PROFILE.adt' | translate }}</p>
        <mat-form-field class="dh-form" appearance="outline">
          <mat-select [formControlName]="'additive'">
            <mat-option *ngFor="let item of additives" value="{{ item.code }}">
              {{ item.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <p class="dh-label-forms">{{ 'PROVIDER.type_of_cement' | translate }}</p>
        <mat-form-field class="dh-form" appearance="outline">
          <mat-select [formControlName]="'type_of_cement'">
            <mat-option *ngFor="let item of cementTypes" value="{{ item.code }}">
              {{ item.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <p class="dh-label-forms">{{ 'Planta' | translate }}</p>
        <mat-form-field class="dh-form" appearance="outline">
          <mat-select [formControlName]="'plant'">
            <mat-option *ngFor="let item of plants" [value]="item.id">
              {{ item.plant_name }}
            </mat-option>
          </mat-select>
        </mat-form-field>


        <div class="dh-two-controls">
          <p class="dh-label-forms">{{ 'PROFILE.pricem3' | translate }}</p>
          <p class="dh-label-forms dh-hide">
            {{ 'PROVIDER.productStatus' | translate }}
          </p>
        </div>
        <div class="dh-two-controls">
          <mat-form-field class="dh-form-meter dh-icon-dollar" appearance="outline">
            <input [formControlName]="'price_per_cubic_meter'" matInput />
            <span matPrefix>$&nbsp;</span>
          </mat-form-field>
          <p class="dh-label-forms dh-mobile-show">
            {{ 'PROVIDER.productStatus' | translate }}
          </p>
          <mat-slide-toggle class="dh-slide-togle" [formControlName]="'status'">
            {{ 'PROVIDER.published' | translate }}
          </mat-slide-toggle>
        </div>
        <p class="dh-label-forms">{{ 'PROVIDER.applications' | translate }}</p>
        <div class="dh-two-controls-aplicaciones">
          <button class="dh-button-select dh-button-mobile-first" [ngClass]="{
              'dh-button-selected':
                formControls.get('discharge_type')?.value === 'discharge_with_pump' && !determinateEdit(),
              'dh-button-unselect':
                formControls.get('discharge_type')?.value !== 'discharge_with_pump' && !determinateEdit(),
              'dh-button-selected-disabled ':
                formControls.get('discharge_type')?.value === 'discharge_with_pump' && determinateEdit(),
              'dh-button-unselect-disabled':
                formControls.get('discharge_type')?.value !== 'discharge_with_pump' && determinateEdit()
            }" (click)="
              formControls
                .get('discharge_type')
                ?.setValue('discharge_with_pump')
            " mat-flat-button color="primary" [disabled]="determinateEdit()">
            {{ 'PROFILE.bombDesc' | translate }}
          </button>
          <button class="dh-button-select dh-button-selected" mat-flat-button [ngClass]="{
              'dh-button-selected':
                formControls.get('discharge_type')?.value === 'direct_discharge' && !determinateEdit(),
              'dh-button-unselect':
                formControls.get('discharge_type')?.value !== 'direct_discharge' && !determinateEdit(),
              'dh-button-selected-disabled ':
                formControls.get('discharge_type')?.value === 'direct_discharge' && determinateEdit(),
              'dh-button-unselect-disabled':
                formControls.get('discharge_type')?.value !== 'direct_discharge' && determinateEdit()
            }" color="primary" style="margin-left: 1vw" (click)="
              formControls
                .get('discharge_type')
                ?.setValue('direct_discharge')
            " [disabled]="determinateEdit()">
            {{ 'PROFILE.direct' | translate }}
          </button>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions class="buttons-acions">
      <button class="dh-button-save" mat-flat-button color="primary" [disabled]="formControls.invalid"
        (click)="onSubmit()">
        {{ (determinateEdit() ? 'PROVIDER.updatePrice' : 'PROFILE.createConcrete') | translate }}
      </button>
    </mat-dialog-actions>
  </form>
</ng-container>
<ng-template #confirm>
  <div mat-dialog-title class="dh-header-confirm">
    <button mat-icon-button mat-dialog-close class="close-button">
      <mat-icon aria-label="Cerrar">close</mat-icon>
    </button>
  </div>
  <mat-dialog-content class="dh-container-confirm">
    <h1 class="dh-center-text">{{'PROVIDER.notAvailableQuoteProductTitle' | translate }}</h1>
    <p>{{'PROVIDER.notAvailableQuoteProductPhrase' | translate }}</p>
  </mat-dialog-content>
  <mat-dialog-actions class="dh-container-actions-confirm">
    <button class="dh-button-select dh-button-mobile-first dh-button-unselect" mat-flat-button color="primary"
      (click)="dialogRef.close()">
      {{ 'PROVIDER.leter' | translate }}
    </button>
    <button class="dh-button-select dh-button-mobile-first dh-button-selected" mat-flat-button color="primary"
      (click)="save()">
      {{ 'PROVIDER.chanchePrice' | translate }}
    </button>
  </mat-dialog-actions>
</ng-template>