import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DochProductsService {
  constructor(
    private htppClient: HttpClient,
    private authService: AuthService,
  ) {}

  public getConcreteConfig(): Observable<any> {
    return this.htppClient.get(
      environment.rootUrl + '/api-v1/concrates/config',
      {
        headers: this.authService.getToken(),
      },
    );
  }
}
