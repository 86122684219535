export const DEFAULT_LANGUAGE = 'es';
export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const CAPITAL_LETTER =
  /^(?=(?:.*[A-Z]{1}))(?=(?:.*[a-z]{1}))(?=(?:.*[0-9]{1}))/;
export const CAPITAL = /^(?=(?:.*[A-Z]))(?=(?:.*[a-z]))/;
export const TYPE_USER = {
  PROVIDER: 'provider',
  CONTRACTOR: 'contractor',
  CIVIL_ENGINEER: 'civilEngineer',
  ARCHITECT: 'architect',
  RETAIL_BUYER: 'retailBuyer',
  LOGISTICS: 'logistics',
  CONSTRUCTION_CONSULTANT: 'constructionConsultant',
  GOVERNMENT_AGENCY: 'governmentAgency',
  TOOL_EQUIPMENT_COMPANY: 'toolEquipmentCompany',
  INVESTOR_ANALYST: 'investorAnalyst',
};
export const INFO_TERMS = [
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
];
export const QUESTIONS = [
  {
    question: '¿Qué es dochmart?',
    answer:
      'Somos el primer market place en la venta de concreto premezclado en México. Nuestro servicio está disponible de acuerdo con la ubicación de la obra, llegamos a tiempo, sin retrasos y con un excelente servicio.',
  },
  {
    question: '¿Qué necesito para comenzar?',
    answer:
      'Es importante que para iniciar la experiencia dochmart inicies sesión o te registres, asegúrate que tus datos sean correctos, una vez completada la información comenzaras a vivir la experiencia de compra de concreto premezclado.',
  },
  {
    question: '¿Cómo comprar concreto premezclado?',
    answer:
      'Comprar concreto nunca había sido tan sencillo, inicia sesión en nuestra plataforma, realiza la cotización con las especificaciones de diseño de tu concreto, asegúrate que la información este correcta, si necesitas servicio de bombeo configúralo. ',
  },
  {
    question: '¿En cuánto tiempo llega mi pedido?',
    answer:
      'En dochmart cuidamos cada detalle del proceso, garantizando la calidad de nuestros materiales y servicios. Una vez procesado el pago, recibirás a dochmart en un plazo no mayor a 72 horas.',
  },
  {
    question: '¿Cuáles son los beneficios de dochmart?',
    answer:
      'Hemos transformado la comercialización de materiales de construcción al alcance de un clic, somos una plataforma, segura, rápida y fácil de usar.',
  },
  {
    question: '¿Cuál es la cantidad mínima que puedo adquirir en dochmart?',
    answer:
      'Realiza tu compra desde 1 metro cúbico, estamos para asesorarte durante tu compra, por lo que es importante que cuentes con las especificaciones adecuadas del diseño de tu concreto.',
  },
  {
    question: '¿Qué marcas de concreto manejan?',
    answer:
      'En dochmart trabajamos con las mejores marcas de concreto del país, no lo pienses más y adquiere tu concreto con los mejores.',
  },
  {
    question: 'Tengo un problema con el pago',
    answer:
      'Comprueba si tienes fondos suficientes antes de completar la compra. En caso de que el problema persistavte asesoramos 24/7. ',
  },
  {
    question: '¿Cómo rastrear mi pedido en dochmart?',
    answer:
      '•	Ingresa a tu cuenta \n•	Dirígete al apartado de compras \n•	Selecciona tu pedido y desplegará la información completa del estatus de tu pedido',
  },
  {
    question: '¿Qué tan seguro es dochmart?',
    answer:
      'Para nosotros tu seguridad de compra es lo más importante, cumplimos con los estándares altos de calidad en nuestro servicio donde la protección de tus datos personales está a salvo, facilidad de compra y un seguimiento personalizado ',
  },
  {
    question: '¿Tienen una aplicación móvil?',
    answer: 'Si, buscala como dochmart en la play store y app store ',
  },
  {
    question: '¿Cómo puedo crear una cuenta en su sitio web?',
    answer:
      'En la opción de “Inicia sesión o regístrate” en el apartado de “crear cuenta” solo debes de llenar el formulario con tus datos y listo estas registrado en nuestra plataforma  ',
  },
  {
    question: '¿Cómo puedo restablecer la contraseña?',
    answer:
      'La contraseña la puedes cambiar dando clic en el menú “Inicia sesión o regístrate” en la opción de “olvidaste tu contraseña” con esto te enviaremos un mensaje a tu correo para que puedas restablecer tu contraseña y puedas acceder a nuestra plataforma, recuerda que la contraseña debe de ser más de 8 caracteres',
  },
  {
    question:
      '¿Cómo puedo cambiar los datos de mi cuenta, o alguna información personal?',
    answer:
      'Primero debes de iniciar sesión con tu correo y contraseña, después en el menú click en la opción “mi cuenta” - “ver perfil” y podrás ver tu información personal, puedes editar la información que desees',
  },
  {
    question:
      '¿Puedo ponerme en contacto directamente con el vendedor si tengo preguntas sobre su producto?',
    answer:
      'Inicia sesión y realiza tu cotización al y terminar tu cotización uno de nuestros asesores se comunicará contigo para responder dudas',
  },
  {
    question: '¿Cómo efectúo un pago en su sitio web? ¿Es seguro?',
    answer:
      'En la opción de “ver pedidos” puedes efectuar el pago una vez que tu cotización ya fue aprobada y por supuesto el pago es seguro contamos con encriptación de la sesión y más de 32 candados de seguridad en las trasferencias bancarias además ofrecemos diferentes formas de pago, entra y cotiza ya',
  },
  {
    question: '¿para qué sirve el botón de recomendar? ',
    answer:
      'Puedes invitar a tus amigos, familiares, contactos a que se registren en la plataforma de dochmart solo debes de ingresar el correo electrónico y listo les llegara una invitación ',
  },
  {
    question: '¿Cómo puedo hacer una cotización?',
    answer:
      'Solo debes de registrarte en nuestra plataforma o iniciar sesión, ir al menú “Cotizar concreto ahora “, rellenar el formulario y listo cotizaciones de concreto en menos de 5 minutos',
  },
];

export const FORMS_CEMENT = {
  titule: [
    'Resistencias',
    'Fraguado',
    'Agregado',
    'Rev',
    'Tipo de cemento',
    'Aditivos',
    'Clase',
    'Elementos a colar',
    'Volumen m3',
  ],
  copys: [
    'Se define como la capacidad para soportar una carga por unidad de área, y se expresa en términos de esfuerzo, generalmente en kg/cm2',
    'El fraguado es el proceso de endurecimiento y pérdida de plasticidad del concreto',
    'Son  compuestos de materiales geológicos tales como, la piedra, la arena y la grava',
    'El revenimiento es una medida de la consistencia del concreto, que nos indica qué tan trabajable está el concreto antes de pasar a estado endurecido.',
    'características y propiedades especificas del cemento/aditivo Los aditivos son aquellos ingredientes del concreto que, además del Cemento Portland, el agua y los agregados, se adicionan inmediatamente antes o durante el mezclado. Aditivos diversos: Para mejorar la trabajabilidad y o la adherencia.',
    'metro cúbico es una unidad de volumen.',
  ],
  resistors: [
    '100 kg/cm2',
    '150 kg/cm2',
    '200 kg/cm2',
    '250 kg/cm2',
    '300 kg/cm2',
    '350 kg/cm2',
    '400 kg/cm2',
    '450 kg/cm2',
    '500 kg/cm2',
    'F030 MR',
    'F035 MR',
    'F038 MR',
    'F040 MR',
    'F042 MR',
    'F045 MR',
    'F048 MR',
  ],
  resistorsCode: [
    'C100',
    'C150',
    'C200',
    'C250',
    'C300',
    'C350',
    'C400',
    'C450',
    'C500',
    'F030',
    'F035',
    'F038',
    'F040',
    'F042',
    'F045',
    'F048',
  ],
  fraguado: [
    // '12 Horas',
    '24 Horas',
    '48 Horas',
    '72 Horas',
    // '7 Días',
    // '14 Días',
    // '28 Días',
  ],
  fraguadoCode: ['12HR', '24HR', '48HR', '07', '14', '28'],
  tma: ['10 mm', '20 mm = 3/4', '40 mm = 1 1/2'],
  tmaCode: ['10', '20', '40'],
  rev: ['10', '12', '14', '16', '18', '20', '22'],
  cement: ['CPC40 cemento', 'CPC40RS resistente a sulfatos'],
  cementCode: ['N', 'RS'],
  additives: ['N/A','Imper 1%', 'Imper 2%', 'Fibra 600 grs', 'Fibra 900 grs'],
  additivesCode: ['N/A', 'Imper 1%', 'Imper 2%', 'Fibra 600 grs', 'Fibra 900 grs'],
  class: ['2 convencional', '1 estructural'],
  classCode: ['C2', 'C1'],
};

export const FORMS_CEMENT_TYPE = {
  titule: ['Peso', 'Unidad', 'Tipo'],
  copys: [
    'Se define como la capacidad para soportar una carga por unidad de área, y se expresa en términos de esfuerzo, generalmente en kg/cm2',
    'El fraguado es el proceso de endurecimiento y pérdida de plasticidad del concreto',
    'Son  compuestos de materiales geológicos tales como, la piedra, la arena y la grava',
  ],
  weight: ['kg'],
  unit: ['30', '50'],
  type: ['Gris', 'Blanco', 'Mortero'],
};

export const FORMS_BOMB = {
  titule: [
    'Elementos a colar',
    'Bomba',
    'Altura estacionaria',
    'Altura pluma',
    'Adicionaes',
    'Volumen m3',
    'Altura',
  ],
  bombs: ['Estacionaria', 'Pluma'],
  static: [
    '16 mts',
    '17 a 30 mts',
    '31 a 42 mts',
    '43 a 54 mts ',
    '55 a 63 mts ',
    '64 a 75 mts',
    '76 a 86 mts',
  ],
  pen: [
    '28 mts',
    '29 a 32 mts',
    '33 a 36 mts',
    '37 a 42 mts',
    '43 a 44 mts',
    '45 mts',
    '46 a 50 mts',
    '51 a 52 mts',
  ],
  additional: ['Tramo de tuberia por metro', 'Movimientos de tuberia'],
};

export const TERMS = [
  ['<p> <strong> 1. Información de Dochmart. </strong> </p>', 'color: #000;'],
  [
    '<p> Dochmart, S.A.P.I. de C.V., con domicilio en Paseo de la Reforma, número 180, piso 14, interior A, Colonia Juárez, Alcaldía Cuauhtémoc, es quien vende de manera directa al cliente, los productos ofrecidos en la página, sujetando a los Términos y Condiciones aquí establecidos. </p>',
    '',
  ],
  [
    '<p> <strong> 2. Aceptación de los Términos y Condiciones. </strong> </p>',
    'color: #000;',
  ],
  [
    '<p> El Cliente acepta los términos y condiciones aquí establecidos, por lo que te pedimos cada que ingreses a nuestra página https://www.dochmart.com/, deveras de revisar detenidamente antes de realizar cualquier transacción, así como conducirte con verdad la información solicitada para la gestión de cotizaciones y compra de producto. </p>',
    '',
  ],
  ['<p> <strong> 3. Uso de la Página. </strong> </p>', 'color: #000;'],
  [
    '<p> La Página ha sido diseñada únicamente para realizar cotizaciones, compras y pagos de los productos ofertados en ella. Por tal motivo, no podrás hacer un uso distinto de la Pagina, tampoco está permitido copiar, reenviar, reproducir, reutilizar, vender, transmitir, distribuir, bajar, otorgar licencia, modificar, publicar o usar de alguna otra manera el contenido de la Página para fines públicos o comerciales. En ningún caso se podrás entender o interpretar que se está entregando u otorgando alguna concesión o licencia respecto de ningún derecho de propiedad intelectual. No se permite formar vínculo o hacer referencias de la Página, a menos que se permita expresamente por escrito por parte de Dochmart. </p>',
    '',
  ],

  ['<p> <strong> 4. Obligaciones del Cliente. </strong> </p>', 'color: #000;'],
  [
    '<p> Cada vez que haces uso de esta Página, te obligas a través de estos Términos y Condiciones a: </p>',
    '',
  ],
  [
    '<ul> <li> Hacer uso de esta Página de conformidad con los Términos y Condiciones aquí establecidos, así como a las Condiciones de Uso de la Página que podrás encontrar dentro de la misma. </li> </ul>',
    '',
  ],
  [
    '<ul> <li> Realizar operaciones legítimas, ya sea como persona física o persona moral en calidad de empleado, socio comercial, asesor, consultor o cualquier otra relación comercial con la persona moral. </li> </ul>',
    '',
  ],
  [
    '<ul> <li> Cumplir con lo establecido por Ley del Impuesto Sobre la Renta, Ley del Valor Agregado respecto del pago cuotas e impuestos derivados de las transacciones realizadas. </li> </ul>',
    '',
  ],
  [
    '<ul> <li> Proporcionar información veraz, precisa y legítima que se solicite para llevar a cabo la transacción de compraventa de los productos aquí ofrecidos. </li> </ul>',
    '',
  ],
  [
    '<ul> <li> No acceder a través de la Página a información de terceros, sin autorización de ellos. </li> </ul>',
    '',
  ],
  [
    '<ul> <li> No permitir que terceros no autorizados realicen las operaciones a través de la Página sin tu consentimiento. </li> </ul>',
    '',
  ],
  [
    '<ul> <li> El pago de los materiales o servicios mediante la Página, deberán ser de procedencia licita, Dochmart se deslinda de cualquier responsabilidad civil, mercantil, penal y administrativa por la procedencia de los recursos del Cliente. </li> </ul>',
    '',
  ],
  [
    '<ul> <li> No suplantar a ninguna persona física o moral, ni utilizar o proporcionar una dirección de correo falsa o que no corresponda a ti. </li> </ul>',
    '',
  ],
  [
    '<ul> <li> El Cliente será el único responsable de cualquier compra que realice mediante transferencia bancaria y pago con tarjeta. </li> </ul>',
    '',
  ],

  [
    '<p> <strong> 5. Actualización de la información. </strong> </p>',
    'color: #000;',
  ],
  [
    '<p> Dochmart podrá modificar el contenido de la Página en cualquier momento y no asume ningún compromiso de realizar avisos en determinado momento. Como Cliente, tienes la obligación de consultar, verificar o ratificar cualquier información contenida en la Página. </p>',
    '',
  ],

  [
    '<p> <strong> 6. Recabo y uso de información de contacto. </strong> </p>',
    'color: #000;',
  ],
  [
    '<p> Como cliente aceptas proporcionar mediante la Página la información que será utilizada únicamente para realizar las cotizaciones y ventas de nuestros productos, la información que te solicitaremos de forma enunciativa mas no limitativa es la siguiente: Nombre, Teléfono, Domicilio y Correo electrónico, esta información será tratada de acuerdo a lo dispuesto en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (la “LFPDP”), para lo cual deberás de revisar nuestro Aviso de Privacidad, visible en la Página. </p>',
    '',
  ],

  [
    '<p> <strong> 7. Comunicación con el Cliente. </strong> </p>',
    'color: #000;',
  ],
  [
    '<p> Como Cliente aceptas utilizar el correo electrónico como medio de comunicación, por lo que todos los convenios, contratos, facturas, avisos, información o comunicados que se te envíen por este medio, se considerará que reúnen los requisitos legales como si los mismos hubiesen sido efectuados por escrito, por tanto, serán una prueba valida y fehaciente de la relación comercial existente. </p>',
    '',
  ],

  [
    '<p> <strong> 8. Seguridad de la información. </strong> </p>',
    'color: #000;',
  ],
  [
    '<p> Dochmart protege tu información confidencial, si a través de este portal transmites tú información confidencial a un tercero, la protección de tu información es tu responsabilidad y la de la del tercero con quien decidiste compartirla. </p>',
    '',
  ],
  [
    '<p> Como sabes, el uso de medio electrónicos implica un riesgo, el cual al utilizar este portal reconoces y aceptas, por ello te recordamos que el Dochmart no será legalmente responsable por cualquier daño que pudieras sufrir al utilizarlo. </p>',
    '',
  ],

  [
    '<p> <strong> 9. Integridad, autenticidad y archivos de los mensajes de datos. </strong> </p>',
    'color: #000;',
  ],
  [
    '<ul> <li> Tu clave de usuario y tu contraseña servirán para hacer las operaciones propias del este portal a fin de: (a) saber que se trata de ti, (b) determinar que el mensaje no ha sido alterado, y (c) atribuirte el mensaje. </li> </ul>',
    '',
  ],
  [
    '<ul> <li> Los mensajes de datos recibidos o archivados en medios electrónicos, identificados con la clave de usuario y la contraseña, serán atribuibles al usuario respectivo y servirán para evidenciar su origen. </li> </ul>',
    '',
  ],
  [
    '<ul> <li> Deberás cuidar tu clave de usuario y contraseña, ya que todas las operaciones que se hagan con ellas se considerarán hechas por ti. </li> </ul>',
    '',
  ],
  [
    '<ul> <li> Mantener el secreto tu clave de usuario y contraseña, es tu responsabilidad. </li> </ul>',
    '',
  ],
  [
    '<p> <strong> 10. Solicitud de las cotizaciones y Orden de Compra. </strong> </p>',
    'color: #000;',
  ],
  [
    '<p> a) Como Cliente deberás ingresar a la Página de Internet de Dochmart con su usuario y contraseña para iniciar su tramite de cotización. </p>',
    '',
  ],
  [
    '<p> b) Como Cliente deberás de especificar en su solicitud de cotización las especificaciones precisas acerca del concreto: i) Resistencia, ii) Días de fraguado, iii) Agregado, iv) Rev, v) Tipo de cemento/aditivo, vi) Volumen, vii) Clase, viii) Elemento o color o tipo de concentrado solicitados en el rubro 1 de Prepara tu concreto, lo que dará como resultado un número de cotización. </p>',
    '',
  ],
  [
    '<p> c) Como Cliente aceptas recibir mediante correo electrónico y Whats App la Orden de Compra correspondiente la cual contendrá los Datos del Proveedor, Datos Generales del Proyecto, Datos de Entrega, de acuerdo con las especificaciones del Cliente. </p>',
    '',
  ],

  [
    '<p> <strong> 11. Pago de los productos o servicios. </strong> </p>',
    'color: #000;',
  ],
  [
    '<p> El monto total de la Orden de Compra deberá pagarse mediante la Página en una de las dos opciones referidas, i) Pago con Tarjeta de Crédito, ii) Pago con Transferencia Bancaría, la cual será perfeccionada mediante el pago con la Orden de Compra. </p>',
    '',
  ],

  [
    '<p> La transacción de compraventa mediante esta Página es administrada por el proveedor de servicios bancarios por lo que Dochmart no almacena, procesa ni transmite datos bancarios del Cliente. Dochmart busca siempre implementar las mejores prácticas verificando que el Proveedor cuente con las certificaciones Payment Card Industry Data Security Standard así como los requisitos de Ley, aun cuando Dochmart utiliza medios de la seguridad necesarios para proteger su sitio web, el Internet no es 100% seguro, por lo cual sugerimos al Cliente el cuidado y buen uso de su información confidencial (nombre del titular de tarjeta de crédito o débito, número de tarjeta, fecha de expiración, exceptuando el Código de seguridad, el cual en aras de proteger la información confidencial del Cliente); por lo anterior, Dochmart no se hace responsable por daños o mal uso de los datos señalados anteriormente. </p>',
    '',
  ],

  [
    '<p> Referente al método de pago vía Transferencia Bancaria, el Cliente tendrá un máximo de 24 horas para realizar el pago total de su orden de compra, Dochmart informará al Cliente los datos correspondientes para realizar la Transferencia Bancaria a través del correo electrónico registrado en la orden de compra, el Cliente deberá de enviar su comprobante de pago al correo indicado, una vez validado por Dochmart el pago, procederá a la entrega del producto. </p>',
    '',
  ],

  [
    '<p> <strong> 12. Pago de los productos o servicios. </strong> </p>',
    'color: #000;',
  ],
  [
    '<p> Como Cliente deberás definir mediante la Página Fecha, Hora de arranque, Tipo de Descarga, Lugar de Entrega, el Cliente recibirá una confirmación mediante correo electrónico, si por cualquier causa, razón o motivo no te llegará dicha confirmación deberás de contactar a Dochmart mediante correo electrónico o Whats App. </p>',
    '',
  ],

  [
    '<p> De presentarse situaciones ajenas (accidentes, marchas, robo, lluvias, etc.) a Dochmart que pudieran presentarse durante el trayecto de la planta a las instalaciones del Cliente, que sea causa de incumplimiento en tiempo de entrega por Dochmart, Dochmart comunicará la situación inmediatamente al Cliente, al ser causas ajenas a Dochamrt, Dochmart se deslinda de cualquier responsabilidad o daño económico que esto pudiera provocar, Dochmart se pondrá a disposición del Cliente para acordar las nuevas condiciones de entrega del producto. </p>',
    '',
  ],

  [
    '<p> <strong> 13. Conformidad con los términos y condiciones. </strong> </p>',
    'color: #000;',
  ],
  [
    '<p> El acceso, la utilización o la celebración de cualquier operación a través de la Página, significa conformidad con los presentes términos y condiciones que como Cliente declaras haber leído y aceptado. En caso de controversia derivada de la aplicación o interpretación de los presentes términos y condiciones, las partes se someterán al procedimiento de conciliación de la Procuraduría Federal del Consumidor y Tribunales de la Ciudad de México, renunciando a cualquier otra jurisdicción o fuero, presente o futuro que pudiera corresponderte por razones de tu domicilio o cualquier otra causa. </p>',
    '',
  ],

  ['<p> <strong> 14. Contáctanos. </strong> </p>', 'color: #000;'],
  [
    '<p> En caso de tener cualquier duda relacionada a la información ofrecida dentro de la Página, de los productos ofrecidos, de tu cotización de compra, o los Términos y Condiciones, puedes contactarnos a través de nuestro Agente de Servicio al Cliente en el teléfono </p>',
    '',
  ],
];

export const PRIVACY = [
  [
    '<p> Dochmart hace de su conocimiento que la información recabada concerniente a personas morales y/o físicas que obren en poder de Dochmart, será bajo los principios de licitud, consentimiento, información, calidad, finalidad, lealtad, proporcionalidad y responsabilidad, en base a los siguientes términos y condiciones: </p>',
    '',
  ],
  [
    '<p> <strong> I.	La identidad y domicilio del responsable: </strong> </p>',
    'color: #000;',
  ],
  [
    '<p> El presente Aviso de Privacidad (en lo sucesivo referido como “Aviso”) establece los términos y condiciones en virtud de los cuales Dochmart, S.A.P.I. de C.V. (en adelante “Dochmart”), con domicilio para oír y recibir notificaciones en Avenida Paseo de la Reforma, Piso 14 interior A, Colonia Juárez, C.P. 06600, Ciudad de México, en su carácter de “Responsable” (o el Encargado que designe Dochmart) tratará los datos personales del Titular. </p>',
    '',
  ],
  [
    '<p> <strong> II.	Consentimiento del titular: </strong> </p>',
    'color: #000;',
  ],
  [
    '<p> Para efectos de lo dispuesto en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (la “LFPDP”) y demás legislación aplicable, el Titular manifiesta: (i) que el presente Aviso le ha sido dado a conocer por el Responsable, (ii) haber leído, entendido y acordado los términos expuestos en este Aviso, por lo que otorga su consentimiento respecto del tratamiento de sus datos personales, el Responsable recabara por medios electrónicos, ópticos, por cualquier otra tecnología, o por signos inequívocos. Lo siguiente: (i) nombre, (ii) teléfono, (iii) dirección, (iv) correo electrónico, (v) referencias, (vi) contraseña. </p>',
    '',
  ],
  [
    '<p> En caso de que los datos personales recopilados incluyan datos patrimoniales o financieros, mediante la firma del contrato correspondiente, sea en formato impreso, o utilizando medios electrónicos y sus correspondientes procesos para la formación del consentimiento, se llevarán a cabo actos que constituyen el consentimiento expreso del titular y que otorga su consentimiento para que el Dochmart o sus Encargados realicen transferencias y/o remisiones de datos personales en términos del apartado 3 del presente Aviso. </p>',
    '',
  ],
  [
    '<p> <strong> III.	Finalidad del tratamiento de datos: </strong> </p>',
    'color: #000;',
  ],
  [
    '<p> Dochmart recabará los datos personales del Titular con la finalidad de llevar a cabo las actividades enfocadas al cumplimiento de su objeto social, derivadas de las obligaciones de cualquier relación jurídica y/o comercial, para fines informativos o administrativos, que se establezcan entre las partes, que de manera enunciativa mas no limitativa son las siguientes: </p>',
    '',
  ],
  ['<p> 1) Cotizaciones </p>', ''],
  ['<p> 2) Suministro de materiales </p>', ''],
  ['<p> 3) Asesoría sobre proyectos </p>', ''],
  ['<p> 4) Desarrollo de sistemas de gestión de calidad </p>', ''],
  ['<p> 5) Servicio de mantenimiento </p>', ''],
  ['<p> 6) Comercialización de materiales </p>', ''],
  [
    '<p> <strong> IV.	Medios que el responsable ofrece a los titulares para limitar el uso o divulgación de los datos: </strong> </p>',
    'color: #000;',
  ],
  [
    '<p> Los datos personales recabados por Dochmart, no serán cedidos, vendidos, compartidos o transferidos a un tercero, sin que sea previamente informado y otorgue su consentimiento por escrito, sino solamente en aquellos casos que sea requerido por: (i) Instituciones Públicas, Autoridades gubernamentales, administrativas y/o judiciales en la Ciudad de México, Federal, Estatal o Municipal; (ii) Instituciones Financieras de México y el extranjero en donde se tenga la relación comercial; y (iii) afiliadas, empleados, asesores y proveedores de servicio de Dochmart, que así lo soliciten con motivo de realizar sus actividades diarias y en aquellos casos previstos en la Ley. </p>',
    '',
  ],
  [
    '<p> <strong> V.	Procedimiento para ejercer los derechos de acceso, rectificación, cancelación u oposición y revocación del consentimiento: </strong> </p>',
    'color: #000',
  ],
  [
    '<p> El Titular tiene derecho de acceder, rectificar y cancelar sus datos, así como de oponerse al tratamiento de estos o revocar el consentimiento que haya proporcionado presentando una solicitud a petición expresa mediante correo electrónico contacto@dochmart.com; misma que debe contener la información y documentación siguiente: </p>',
    '',
  ],
  [
    '<p> 1. El nombre del titular y domicilio o correo electrónico en donde se comunicará la respuesta a su solicitud; </p>',
    '',
  ],
  [
    '<p> 2.	Copia digitalizada de la Identificación Oficial Vigente del Titular, y en su caso, carta poder e Identificación Oficial Vigente de quien represente al Titular; </p>',
    '',
  ],
  [
    '<p> 3.	La descripción clara y precisa de los datos personales respecto de los que se busca ejercer alguno de los derechos antes mencionados, y </p>',
    '',
  ],
  [
    '<p> 4.	Cualquier otro elemento o documento digitalizado que facilite la localización de los datos personales. </p>',
    '',
  ],
  [
    '<p> 5.	En el caso de las solicitudes de rectificación de datos personales, el Titular deberá también indicar las modificaciones a realizarse y aportar la documentación que sustente su petición. </p>',
    '',
  ],
  ['<p> <strong> VI.	Licitud y transferencia: </strong> </p>', 'color: #000;'],
  [
    '<p> En el caso de que Dochmart requiera transferir a terceros los datos personales del Titular con el objeto de dar cumplimiento a sus obligaciones jurídicas y/o comerciales. Los receptores de los datos personales están obligados por virtud de un contrato correspondiente celebrado con anterioridad, a mantener la confidencialidad de los datos personales transferidos y a observar el presente Aviso. </p>',
    '',
  ],
  [
    '<p> Dochmart y/o sus Empleados podrán comunicar los datos personales recolectados a cualquier otra sociedad del mismo grupo empresarial al que pertenezca el Responsable y que opere con los mismos procesos y políticas internas, sea que se encuentre en territorio nacional o en el extranjero, para su tratamiento con las mismas finalidades descritas en este Aviso. </p>',
    '',
  ],
  [
    '<p> Su información personal puede remitirse, almacenarse y procesarse en un país distinto de donde se proporcionó, lo cual se llevará a cabo de conformidad con las leyes de protección de datos aplicables. </p>',
    '',
  ],
  [
    '<p> Contamos con servidores de bases de datos, que permite administrar los datos de manera segura, procurando en todo momento la seguridad física, mediante controles de acceso a la información y mantenimiento constante. </p>',
    '',
  ],
  [
    '<p> Dochmart no requiere el consentimiento del Titular para realizar transferencias de datos personales nacionales o internacionales en los casos previstos en el Artículo 37 de la LFPDP o en cualquier otro caso de excepción previsto por la misma u otra legislación aplicable. </p>',
    '',
  ],
  [
    '<p> El Titular una vez leído el presente Aviso de Privacidad contará con cinco días hábiles para que manifieste su negativa para el tratamiento de datos personales con respecto a alguno de los incisos anteriores, así mismo quedan a salvo sus derechos ARCO. </p>',
    '',
  ],
  [
    '<p> Cualquier vulneración a los datos o a los repositorios físicos o electrónicos en donde estos sean almacenados por Dochmart, será notificada en su página de internet <a href="https://www.dochmart.com/">https://www.dochmart.com/</a>, a través de medios de comunicación masiva y/o directamente al Titular de los datos. </p>',
    '',
  ],
  [
    '<p> <strong> VII.	Cambios al aviso de privacidad: </strong> </p>',
    'color: #000;',
  ],
  [
    '<p> Dochmart de reserva el derecho de actualizar o modificar el presente Aviso de Privacidad, por cambios requeridos en la Ley o con la intención de mejorar practicas de seguridad, comunicación, recolección de la información, etc. será responsabilidad del Titular revisar periódicamente el contenido del Aviso en el sitio web <a href="https://www.dochmart.com/">https://www.dochmart.com/</a>. El Titular podrá expresar su negativa respecto de los cambios realizados, de no existir negativa expresa el Responsable entenderá que el Titular entendió y está de acuerdo con los términos, lo que constituirá su consentimiento. </p>',
    '',
  ],
];
