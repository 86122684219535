import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ProviderService } from 'src/app/services/provider.service';
import { SidebarService } from 'src/app/services/sidebar.service';

@Component({
  selector: 'app-sidebar-provider',
  templateUrl: './sidebar-provider.component.html',
  styleUrls: ['./sidebar-provider.component.scss'],
})
export class SidebarProviderComponent implements OnInit {
  isButtonClicked = false;
  sidebarIsOpen = false;
  userModuleOptions: any = [];

  isAProviderUser = false;
  registerStepCompletation = false;
  public isOperatorUr = false;

  constructor(
    private sidebarService: SidebarService,
    private authService: AuthService,
    private providerService: ProviderService,
  ) {}

  openSideNav() {
    this.isButtonClicked = !this.isButtonClicked;
  }

  public userCanSeeSidebar(): boolean {
    if (this.isAProviderUser) {
      if (this.registerStepCompletation) {
        return true;
      }
      return false;
    }
    return true;
  }

  ngOnInit(): void {
    this.isOperatorUr = this.authService.hasTypeUserLogged('operatorUR');
    this.isAProviderUser = this.authService.hasTypeUserLogged('provider');
    if (this.isAProviderUser) {
      this.providerService.getProgressStatus().then((response: any) => {
        if (response.data.status) {
          if (response.data.status.step_completation_date) {
            this.registerStepCompletation = true;
          }
        }
      });
    }
    this.sidebarService.isOpen$.subscribe((response) => {
      this.sidebarIsOpen = response;
    });

    this.userModuleOptions = this.authService.getUserModuleOptions();
  }

  public hasAccessToPlants() {
    return (
      this.authService.hasTypeUserLogged('provider') ||
      this.authService.hasTypeUserLogged('operationalMaster')
    );
  }

  public handleSideBarMenu() {
    this.sidebarService.toggle();
    this.sidebarService.isOpen$.subscribe((response) => {
      this.sidebarIsOpen = response;
    });
  }
}
