import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { LoadingUsersInteractionService } from 'src/app/administrator/users/services/loading-users-interaction.service';
import { ProviderService } from 'src/app/services/provider.service';
import { FORMS_CEMENT } from 'src/app/shared/data';
import { PlantInformationService } from '../../configuration/services/plantInformation.service';
import { DochProductsService } from '../services/doch-products.service';
import {
  IProductFeature,
  IProductFeatureWithOnlyName,
} from '../interfaces/IProductFeature';

@Component({
  selector: 'app-products-dialog',
  templateUrl: './products-dialog.component.html',
  styleUrls: ['./products-dialog.component.scss'],
})
export class ProductsDialogComponent implements OnInit {
  formControls!: FormGroup;
  dataFormsCement = FORMS_CEMENT;
  bornDisable: string[] = [
    'endurance',
    'setting',
    'aggregate',
    'applications',
    'detail_concrate',
    'slump',
    'additive',
    'type_of_cement',
    'plant',
  ];
  confirmStep = false;
  plants: any = [];
  concretConfig: any = [];

  additives: IProductFeature[] = [];
  aggregates: IProductFeature[] = [];
  cementTypes: IProductFeature[] = [];
  classes: IProductFeature[] = [];
  resistances: IProductFeature[] = [];
  settings: IProductFeature[] = [];
  slumps: IProductFeatureWithOnlyName[] = [];

  dataRow!: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private providerService: ProviderService,
    public dialogRef: MatDialogRef<ProductsDialogComponent>,
    private loadingUsersInteractionService: LoadingUsersInteractionService,
    private toastService: ToastrService,
    private plantInformationService: PlantInformationService,
    private dochProductsService: DochProductsService,
  ) {}

  getConcretConfig(): void {
    this.dochProductsService.getConcreteConfig().subscribe({
      next: (response) => {
        if (response.data.success) {
          this.additives = response.data.data.additives;
          this.aggregates = response.data.data.aggregates;
          this.cementTypes = response.data.data.cementTypes;
          this.classes = response.data.data.classes;
          this.resistances = response.data.data.resistances;
          this.settings = response.data.data.settings;
          this.slumps = response.data.data.slumps;
        }
      },
    });
  }

  ngOnInit(): void {
    this.getConcretConfig();
    this.getProviderPlants();
    this.dataRow = this.data.dataRow;
    const controls = {
      endurance: this.formBuilder.control(
        this.setValueAndDisabledForm(
          null,
          this.dataRow,
          'endurance',
          'endurance',
        ),
        [Validators.required],
      ),
      setting: this.formBuilder.control(
        this.setValueAndDisabledForm(null, this.dataRow, 'setting', 'setting'),
        [Validators.required],
      ),
      aggregate: this.formBuilder.control(
        this.setValueAndDisabledForm(
          null,
          this.dataRow,
          'aggregate',
          'aggregate',
        ),
        [Validators.required],
      ),
      slump: this.formBuilder.control(
        this.setValueAndDisabledForm(null, this.dataRow, 'slump', 'slump'),
        [Validators.required],
      ),
      type_of_cement: this.formBuilder.control(
        this.setValueAndDisabledForm(
          null,
          this.dataRow,
          'type_of_cement',
          'type_of_cement',
        ),
        [Validators.required],
      ),
      applications: this.formBuilder.control(
        this.setValueAndDisabledForm(
          null,
          this.dataRow,
          'applications',
          'applications',
        ),
        [Validators.required],
      ),
      additive: this.formBuilder.control(
        this.setValueAndDisabledForm(
          null,
          this.dataRow,
          'additive',
          'additive',
        ),
        [Validators.required],
      ),
      price_per_cubic_meter: this.formBuilder.control(
        this.setValueAndDisabledForm(
          '',
          this.dataRow,
          'price_per_cubic_meter',
          'price_per_cubic_meter',
        ),
        [Validators.required],
      ),
      detail_concrate: this.formBuilder.control(
        this.setValueAndDisabledForm(
          '',
          this.dataRow,
          'detail_concrate',
          'detail_concrate',
        ),
        [Validators.required],
      ),
      status: this.formBuilder.control(
        this.setValueAndDisabledForm(false, this.dataRow, 'status', 'status'),
      ),
      discharge_type: this.formBuilder.control(
        this.setValueAndDisabledForm(
          '',
          this.dataRow,
          'discharge_type',
          'discharge_type',
        ),
        [Validators.required],
      ),

      plant: this.formBuilder.control(null, [Validators.required]),

      kind: this.formBuilder.control(''),
      items_to_queue: this.formBuilder.control(''),
      empty_charger: this.formBuilder.control('30.0'),

      sku: this.formBuilder.control(''),
      class: this.formBuilder.control(''),
    };
    this.formControls = this.formBuilder.group(controls);

    if (Object.keys(this.dataRow).length) {
      this.formControls.get('plant')?.setValue(this.dataRow.plant_id);
      this.formControls.get('plant')?.disable();
    }
  }

  getProviderPlants() {
    this.plantInformationService.getPlants().subscribe({
      next: (response) => {
        this.plants = response.data.plants;
      },
      error: (error) => {},
    });
  }

  setValueAndDisabledForm(
    defualtvalue: any | string,
    valueRow?: any,
    campo?: string,
    controlName?: string,
  ): any {
    if (Object.keys(valueRow).length > 0 && campo && controlName) {
      return {
        value: this.valueCase(controlName, valueRow[campo]),
        disabled:
          this.bornDisable.findIndex((c) => c === controlName) === -1
            ? false
            : true,
      };
    }
    return { value: defualtvalue, disabled: false };
  }

  valueCase(controlName: string, value: any): string | boolean {
    switch (controlName) {
      case 'plant':
        return value;
      case 'endurance':
        return value;
      case 'discharge_type':
        if (value == 'Descarga directa') {
          return 'direct_discharge';
        } else {
          return 'discharge_with_pump';
        }

      case 'setting':
        return value;
      case 'aggregate':
        return value;
      case 'type_of_cement':
        return value;
    }
    return value;
  }

  setAutoGeneretForm(): any {
    this.formControls.get('class')?.setValue(this.determinarClase());
    let valuesFormToModify = this.formControls.getRawValue();

    const enduranceCode = this.formControls.value['endurance'];
    valuesFormToModify = {
      ...valuesFormToModify,
      endurance: enduranceCode,
    };
    const settingCode = this.formControls.value['setting'];

    valuesFormToModify = {
      ...valuesFormToModify,
      setting: settingCode,
    };
    const aggregateCode = this.formControls.value['aggregate'];
    valuesFormToModify = {
      ...valuesFormToModify,
      aggregate: aggregateCode,
    };

    valuesFormToModify['type_of_cement'] =
      this.formControls.get('type_of_cement')?.value === 'CPC40 cemento'
        ? 'N'
        : 'RS';
    let skuForm = '';
    skuForm =
      enduranceCode +
      '-' +
      settingCode +
      '-' +
      aggregateCode +
      '-' +
      valuesFormToModify['slump'] +
      '-' +
      'class' +
      '-' +
      valuesFormToModify['type_of_cement'];
    valuesFormToModify = {
      ...valuesFormToModify,
      sku: skuForm,
    };
    return valuesFormToModify;
  }

  determinarClase(): string {
    switch (this.formControls.get('endurance')?.value) {
      case '100 kg/cm2':
      case '150 kg/cm2':
      case '200 kg/cm2':
        return 'C2';
      default:
        return 'C1';
    }
  }

  determinateEdit(): boolean {
    return Object.keys(this.dataRow).length > 0;
  }

  onSubmit(): void {
    if (Object.keys(this.dataRow).length > 0) {
      this.confirmStep = true;
      this.dialogRef.updateSize('539px');
    } else {
      this.loadingUsersInteractionService.notifyLoading(true);
      this.providerService.addConcrate(this.setAutoGeneretForm()).subscribe({
        next: (response: { data: { message: string; new_concrate: any } }) => {
          this.loadingUsersInteractionService.notifyLoading(false);
          const new_concrate = response.data.new_concrate;
          new_concrate.type_discharge =
            this.formControls.get('discharge_type')?.value;
          this.dialogRef.close(new_concrate);
        },
        error: (error) => {
          this.toastService.error(error?.error?.data?.message, 'Error', {
            disableTimeOut: false,
            timeOut: 4000,
            tapToDismiss: false,
            positionClass: 'toast-top-right',
            toastClass: 'toast-icon custom-toast-error',
          });
          this.loadingUsersInteractionService.notifyLoading(false);
          this.dialogRef.close(error?.error?.data?.message);
        },
      });
    }
  }

  save() {
    this.loadingUsersInteractionService.notifyLoading(true);
    const toSendValues = {
      service_id: this.dataRow['id'],
      price_per_cubic_meter:
        this.formControls.value['price_per_cubic_meter'].toString(),
      additional: '',
      status: this.formControls.value['status'],
      type_service: 'concrate',
    };
    this.providerService.updateService(toSendValues).subscribe({
      next: (response: {
        data: { message: string; updated_concrate: any };
      }) => {
        this.loadingUsersInteractionService.notifyLoading(false);
        this.dialogRef.close(response.data.updated_concrate);
      },
      error: (error) => {
        this.loadingUsersInteractionService.notifyLoading(false);
        if (error?.error?.error?.details) {
          let valuesErrors = '';
          error?.error?.error?.details.forEach(
            (datail: { message: string }) => {
              valuesErrors = valuesErrors + datail.message;
            },
          );
          this.dialogRef.close(valuesErrors);
        } else {
          this.dialogRef.close(error?.error?.data?.message);
        }
      },
    });
  }
}
